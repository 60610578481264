import get from 'lodash/get';
import { MINUTES_PER_HOUR, rehydrate } from '@emobg/web-utils';
import { MFA } from '@/constants/mfa';

/**
 * Makes a request to send a new code to the user. Making this call will result on the invalidation of the previous code.
 *
 * Also, this function throttles the requests you can make to the resend code endpoint. Time between requests can be specified in minutes when preparing the function.
 *
 * @typedef {Object} PrepareResendCodeParams
 * @property {external.authentication} authenticationApiService External service for authentication from web-api-client
 * @property {string} userIdpUuid The current user's idp uuid
 * @property {number} minutesUntilNextRequest The amount of time in minutes until the next request can be made.
 *
 * @typedef {Object} ResendCodeParams
 * @property {boolean} bypassNextResendCodeDelay Choose wether or not apply delay for resending next code
 *
 * @param {PrepareResendCodeParams} prepareResendParams
 * @returns {(params: ResendCodeParams) => Promise<Boolean>} A promise resolving to a boolean value to indicate if the api call was made or not
 */
export const prepareResendCode = prepareResendParams => async resendParams => {
  const lastCodeResentAt = get(rehydrate.get(MFA.lastResentCodeStorageKey), prepareResendParams.userIdpUuid);
  const currentTime = moment().unix();
  const invokeResendCode = () => prepareResendParams.authenticationApiService.postResendCode(prepareResendParams.userIdpUuid);
  let codeHasBeenSent = false;

  if (get(resendParams, 'bypassNextResendCodeDelay')) {
    codeHasBeenSent = await invokeResendCode();
  } else if (!lastCodeResentAt) {
    codeHasBeenSent = await invokeResendCode();
    const validUntilInHours = prepareResendParams.minutesUntilNextRequest / MINUTES_PER_HOUR;
    rehydrate.set(MFA.lastResentCodeStorageKey, prepareResendParams.userIdpUuid, currentTime, validUntilInHours);
  }

  return Promise.resolve(codeHasBeenSent);
};
