<template>
  <ui-modal
    :header="$t('mfa.text.title')"
    :size="SIZES.small"
    open
    class="MFAVerificationForm"
  >
    <div slot="body">
      <p>{{ $t('mfa.text.description_with_email', { email: userEmail }) }}</p>
      <div class="d-flex justify-content-center mt-4 mb-3">
        <MFAVerificationInput
          :code-value="verificationCode"
          :error="errorMessage"
          :disabled="isVerifyingCode"
          @change="onCodeChange"
          @copyError="onVerificationCopyError"
        />
      </div>
      <div class="d-flex align-items-center">
        {{ $t('mfa.text.code_not_received') }}
        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
          :loading="isResendingCode"
          :disabled="isResendCodeButtonDisabled"
          data-test-id="resend_code-button"
          class="MFAVerificationForm__resendButton pl-0"
          @clickbutton="executeResendCodeFlow"
        >
          {{ $t('mfa.action.resend_code') }}
        </ui-button>
      </div>
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-center"
    >
      <ui-button
        v-bind="fetchButtonSpecs()"
        :loading="isVerifyingCode"
        :disabled="isVerifyButtonDisabled"
        data-test-id="verify_code-button"
        @clickbutton="executeCodeVerificationFlow"
      >
        {{ $t('mfa.action.verify_code') }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import moment from 'moment';
import delay from 'lodash/delay';
import omit from 'lodash/omit';
import get from 'lodash/get';
import includes from 'lodash/includes';
import size from 'lodash/size';
import { external } from '@emobg/web-api-client';
import { SIZES } from '@emobg/web-components';
import {
  DELAY,
  getIdpUuid,
  KEYCODE,
  rehydrate,
} from '@emobg/web-utils';
import { useGtm } from '@gtm-support/vue2-gtm';
import {
  computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { useTranslations } from '@/composable/App/Translations';
import { MFA } from '@/constants/mfa';
import { useAuth } from '@/composable/Api/Auth/auth';
import { useSegment } from '@/composable/Segment/segment';
import { GTMEvents } from '@/constants/gtm';
import { SEGMENT_EVENTS } from '@/constants/segment';
import { getUserPersistedData, setUserPersistedData } from '@/helpers/user/tracking/trackingData';
import { useTheme } from '@/composable/Theme/useTheme';

import MFAVerificationInput from './MFAVerificationInput';
import { prepareResendCode } from './resend-code';
import { prepareVerifyCode } from './verify-code';

export default {
  name: 'VerificationForm',

  components: {
    MFAVerificationInput,
  },

  props: {
    onSucceededVerification: {
      required: true,
      type: Function,
    },
    onSucceededResendCode: {
      required: true,
      type: Function,
    },
    onError: {
      required: true,
      type: Function,
    },
  },

  setup(props) {
    const { idpUuid } = useAuth();
    const { $t, $i18n } = useTranslations();
    const { trackSegment } = useSegment();
    const { fetchButtonSpecs } = useTheme();
    const gtm = useGtm();

    const params = {
      authenticationApiService: external.authentication,
      userIdpUuid: idpUuid,
    };
    const verifyCode = prepareVerifyCode(params);
    const resendCode = prepareResendCode({ ...params, minutesUntilNextRequest: 2 });

    const verificationCode = ref([]);
    const isVerifyingCode = ref(false);
    const isResendingCode = ref(false);
    const isResendCodeButtonDisabled = ref(false);
    const errorMessage = ref('');
    const userEmail = ref('');
    const isVerifyButtonDisabled = computed(() => size(verificationCode.value.join('')) < 4);

    const delayEnableResendCodeButton = () => {
      const validUntil = get(rehydrate.get(MFA.lastResentCodeStorageKey), 'validUntil');
      if (!validUntil) {
        return;
      }

      isResendCodeButtonDisabled.value = true;
      delay(() => {
        isResendCodeButtonDisabled.value = false;
      }, (validUntil - moment().unix()) * DELAY.medium);
    };

    const handleErrorResponse = error => {
      const knownErrorKeys = [
        'authentication.expired_mfa_code',
        'authentication.invalid_mfa_code',
      ];

      const key = get(error, 'response.data.key');
      if (includes(knownErrorKeys, key)) {
        errorMessage.value = parseApiErrorMessage($t, $i18n, error);

        const hasCompany = get(getUserPersistedData(), 'data.has_company', false);
        const eventData = {
          [MFA.errorKey]: key,
          [MFA.userHasCompany]: hasCompany,
        };

        trackSegment({
          name: SEGMENT_EVENTS.tfaFailed,
          data: eventData,
        });
        return;
      }

      props.onError(error);
    };

    const executeCodeVerificationFlow = async () => {
      errorMessage.value = '';
      isVerifyingCode.value = true;

      try {
        const verificationResult = await verifyCode(verificationCode.value);
        props.onSucceededVerification(verificationResult);
      } catch (error) {
        handleErrorResponse(error);
      } finally {
        const extraUserMetadata = omit(getUserPersistedData(), 'validUntil');

        trackSegment({
          name: SEGMENT_EVENTS.tfaCodeSet,
          data: extraUserMetadata,
        });
        gtm.trackEvent({
          event: GTMEvents.tfaCodeSet,
          event_data: { ...extraUserMetadata },
        });

        isVerifyingCode.value = false;
      }
    };

    const submitListener = event => {
      if (!isVerifyButtonDisabled.value && get(event, 'keyCode') === KEYCODE.ENTER) {
        executeCodeVerificationFlow();
      }
    };

    onUnmounted(() => {
      document.removeEventListener('keyup', submitListener);
    });

    onMounted(async () => {
      const shouldSendFirstCode = get(rehydrate.get(MFA.sendFirstCodeFlagStorageKey), getIdpUuid(idpUuid), false);
      document.addEventListener('keyup', submitListener);
      const extraUserMetadata = get(omit(getUserPersistedData(), 'validUntil'), 'data');
      if (shouldSendFirstCode) {
        rehydrate.clear(MFA.sendFirstCodeFlagStorageKey);
        const resendCodeResponse = await resendCode({ bypassNextResendCodeDelay: true });

        const email = get(resendCodeResponse, 'data.email');
        setUserPersistedData({ value: { ...extraUserMetadata, email } });
      }

      userEmail.value = get(getUserPersistedData(), 'data.email');
      delayEnableResendCodeButton();
    });

    const executeResendCodeFlow = async () => {
      isResendingCode.value = true;

      try {
        if (await resendCode()) {
          props.onSucceededResendCode();
          delayEnableResendCodeButton();
        } else {
          isResendCodeButtonDisabled.value = true;
        }
      } catch (error) {
        handleErrorResponse(error);
      }

      const hasCompany = get(getUserPersistedData(), 'data.has_company');

      const eventData = {
        [MFA.userHasCompany]: hasCompany,
      };

      trackSegment({
        name: SEGMENT_EVENTS.tfaResendCode,
        data: eventData,
      });

      gtm.trackEvent({
        event: GTMEvents.tfaResendCode,
        event_data: eventData,
      });

      isResendingCode.value = false;
    };

    const onCodeChange = (data) => {
      verificationCode.value = data;
    };

    function onVerificationCopyError(error) {
      errorMessage.value = error;
    }
    watch(verificationCode, () => {
      errorMessage.value = '';
    });

    return {
      fetchButtonSpecs,
      verificationCode,
      isVerifyingCode,
      errorMessage,
      isVerifyButtonDisabled,
      isResendCodeButtonDisabled,
      isResendingCode,
      executeCodeVerificationFlow,
      executeResendCodeFlow,
      onVerificationCopyError,
      onCodeChange,
      SIZES,
      userEmail,
    };
  },
};
</script>
