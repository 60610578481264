<script>
import filter from 'lodash/filter';
import isNaN from 'lodash/isNaN';
import size from 'lodash/size';
import toArray from 'lodash/toArray';
import invoke from 'lodash/invoke';
import join from 'lodash/join';
import result from 'lodash/result';
import each from 'lodash/each';
import get from 'lodash/get';

import { KEYNAME } from '@emobg/web-utils';
import { ref } from 'vue';
import { useTranslations } from '@/composable/App/Translations';

export default {
  name: 'MFAVerificationInput',
  props: {
    codeValue: {
      type: Array,
      default: () => [],
    },
    length: {
      type: Number,
      default: 4,
    },
    error: {
      type: String,
      default: '',
    },
    isValidValue: {
      type: Function,
      default: (value) => {
        const number = parseInt(value, 10);
        return (!isNaN(number) && number.toString() === value);
      },
    },
    onInputCallback: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const inputs = ref([]);
    const code = ref(new Array(props.length));
    const inputWidth = 48;
    const inputPadding = 16;
    const maxWidth = (inputWidth * props.length) + (inputPadding * (props.length - 1));
    const { $t } = useTranslations();

    const getInput = (element) => element.getElementsByTagName('INPUT')[0];

    const focusNextInput = (inputCount) => {
      if (inputCount >= 0 && inputCount < props.length) {
        const nextInput = inputs.value[inputCount];
        const currentInput = getInput(nextInput);
        result(currentInput, 'focus', () => {});
      }
    };

    const validate = (value) => props.isValidValue(value);

    const bindDigits = (content) => {
      if (content.length <= props.length) {
        const isValidContent = size(filter(content, item => !validate(item))) === 0;
        code.value = isValidContent ? toArray(content.toString()) : code.value;
        focusNextInput(isValidContent ? props.length - 1 : 0);
        emit('change', code.value);
      } else {
        each(inputs.value, input => {
          getInput(input).blur();
        });
        emit('copyError', $t('user_email_verification.error_code_length_exceeded'));
      }
    };

    if (get(props, 'codeValue.length') === props.length) {
      bindDigits(join(props.codeValue, ''));
    } else {
      emit('change', code.value);
    }

    const onPaste = (event) => {
      if (event) {
        const clipboardContent = invoke(event, 'clipboardData.getData', 'text');
        bindDigits(clipboardContent);
      }
    };

    const onKeyUp = (event, index) => {
      const { key } = event;
      if (validate(key)) {
        code.value.splice(index, 1, key);
        focusNextInput(index + 1);
      } else if (key === KEYNAME.BACK_SPACE) {
        code.value.splice(index, 1, null);
      }
    };

    const onKeyDown = (event, index) => {
      const { key } = event;
      if ((key === KEYNAME.BACK_SPACE && !code.value[index]) || key === KEYNAME.ARROW_LEFT) {
        event.preventDefault();
        focusNextInput(index - 1);
      } else if (key === KEYNAME.ARROW_RIGHT) {
        focusNextInput(index + 1);
      }
    };

    const onKeyPress = (event) => {
      const { key } = event;
      if (!validate(key)) {
        event.preventDefault();
      }
    };

    const onInput = () => {
      emit('change', code.value);
      props.onInputCallback();
    };

    const clearError = () => {
      if (props.error) {
        emit('copyError', '');
      }
    };

    return {
      inputs,
      code,
      maxWidth,
      validate,
      bindDigits,
      onPaste,
      onKeyUp,
      onKeyDown,
      onKeyPress,
      onInput,
      clearError,
    };
  },
};
</script>

<template>
  <div
    :class="['MFAVerificationCode d-flex flex-column', {'MFAVerificationCode--error': error}]"
    :style="{ maxWidth : `${maxWidth}px`}"
  >
    <div class="d-inline-flex flex-wrap mb-1 emobg-font-x-large emobg-font-weight-semibold emobg-font-line-height-large">
      <ui-text-input
        v-for="(digit, index) in code"
        :ref="el => { inputs[index] = el }"
        :key="index"
        :value="digit"
        :size="SIZES.large"
        :name="`MFACode_${index}`"
        :class="{ 'mr-3' : index < (length - 1)}"
        maxlength="1"
        @keypress="onKeyPress"
        @keyup="onKeyUp($event, index)"
        @keydown="onKeyDown($event, index)"
        @input="onInput"
        @click="clearError"
        @paste.prevent="onPaste"
      />
    </div>
    <p
      v-if="error"
      class="emobg-color-danger text-break"
      v-html="error"
    />
  </div>
</template>

<style lang="scss">
@import "~@emobg/sass/colors/variables";

.MFAVerificationCode {
  .Ui-TextInput {
    width: 48px;

    &__input__wrapper {
      height: 64px;
    }

    input {
      text-align: center;
    }
  }

  &--error {
    .Ui-TextInput__input__wrapper {
      border-color: map-get($motion-color-palette, "danger");
      border-width: 1px;
    }
  }
}
</style>
