import join from 'lodash/join';
import get from 'lodash/get';
import { getIdpUuid } from '@emobg/web-utils';
import { MFA } from '@/constants/mfa';

/**
 * Validate code and get corresponding key for current user idp uuid and he token value.
 *
 * @typedef {Object} VerifyCodeParams
 * @property {external.authentication} authenticationApiService External service for authentication from web-api-client
 * @property {string} userIdpUuid The current user's idp uuid
 *
 * @typedef {Object} KeyToken
 * @property {string} key The key assigned to this user idp id
 * @property {string} token The token value resulting from the call to validate
 *
 * @typedef {string[]} CodeAsArray The code to validate as an array of strings
 *
 * @param {string} userIdpUuid The current user's idp uuid
 * @returns {(codeAsArray: CodeAsArray) => Promise<KeyToken>}  An object containing the storage key name with user idp uuid concatenated and corresponding token value
 */
export const prepareVerifyCode = params => async codeAsArray => {
  const code = join(codeAsArray, '');

  const response = await params.authenticationApiService.postValidateCode(params.userIdpUuid, { code });
  const key = MFA.userDeviceStorageKey.replace(MFA.userUuidStorageKeyPlaceholder, getIdpUuid(params.userIdpUuid));
  const token = get(response, MFA.responseTokenKey);
  const expirationDate = get(response, MFA.responseTokenExpirationDate);

  return { key, token, expirationDate };
};
